// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import { Skeleton } from '@strongsdcom/erp-shared-js';
import ModuleConfig from 'config/ModuleConfig';
import { BrowserTracing } from '@sentry/tracing';

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('@rails/activestorage').start();
require('channels'); // eslint-disable-line

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

if (process.env.SENTRY_ENVIRONMENT) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT || 'dev-local',
    release: process.env.SENTRY_RELEASE,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new BrowserTracing(),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true
      })
    ]
  });
}

ReactDOM.render(
  <Skeleton config={ModuleConfig} />,
  document.getElementById('react-root')
);
