// @flow

import RoutesConfig from 'config/RoutesConfig';
import LayoutConfig from 'config/LayoutConfig';

export default {
  moduleKey: 'core',
  ...RoutesConfig,
  ...LayoutConfig
};
