// @flow

import React from 'react';
import PersonIcon from '@material-ui/icons/Person';

export default {
  withDrawer: true,
  drawerProps: {
    navigations: [
      {
        links: [{ to: '/profile', icon: <PersonIcon />, label: 'Profile' }]
      }
    ]
  }
};
