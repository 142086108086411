// @flow

export default {
  routes: [
    {
      key: 'redirect_to_profile',
      type: 'redirect',
      path: '/',
      exact: true,
      to: '/profile'
    }
  ]
};
